<template>
    <div>
      <SectionTitle :title="'Kontakt'"></SectionTitle>
      <div class="row justify-content-md-center">
        <div class="col col-12 col-md-6 col-lg-5">
          <div class="card pl-4 pr-4 bg-primary text-white" v-motion-slide-visible-once-left
               :delay="400">
            <div class="card-body description mb-0">
                <p style="font-family: 'coco',serif; font-size: 3rem"><b>Evelyn</b></p>
                <p> <i class="kit-icons ui-1_email-85" style="position: relative; top: 3px;"> </i> <a href="mailto:evelyn.kufeld@gmail.com?subject=Hochzeit" class="text-white">&nbsp;evelyn.kufeld@gmail.com</a></p>
                <p> <i class="kit-icons ui-2_chat-round" style="position: relative; top: 3px;"> </i> <a href="tel:+491726089694" class="text-white">0172 6089694</a></p>
            </div>
          </div>
        </div>
        <div class="col col-12 col-md-6 col-lg-5">
          <div class="card pl-4 pr-4 bg-primary text-white" v-motion-slide-visible-once-right
               :delay="400">
            <div class="card-body description mb-0">
              <p style="font-family: 'coco',serif; font-size: 3rem"><b>Max</b></p>
              <p> <i class="kit-icons ui-1_email-85" style="position: relative; top: 3px;"> </i> <a href="mailto:mail@rinkmax.de?subject=Hochzeit" class="text-white">&nbsp;mail@rinkmax.de</a></p>
              <p> <i class="kit-icons ui-2_chat-round" style="position: relative; top: 3px;"> </i> <a href="tel:+4915786904558" class="text-white">0157 86904558</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import SectionTitle from "@/pages/components/SectionTitle";

export default {
  name: "Registration",
  components: {
    SectionTitle
  }
}
</script>

<style scoped>

</style>