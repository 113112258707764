<template>
  <div class="container">
    <div class="row">
      <div class="col" style="height:130px"><p></p></div>
    </div>
    <h2 class="pb-0 mb-0 text-primary" style="font-family: 'coco',serif; font-size: 6em;" >
      <span>Evelyn & Max</span>
    </h2>
  </div>
</template>

<script>
export default {
  name: "Title"
}
</script>

<style scoped>

</style>