<template>
  <div>
    <SectionTitle :title="'Wir sagen Danke!'"></SectionTitle>
    <div class="container">
      <h5 class="mb-4 text-center">
        <div class="separator separator-primary"></div>
        <div class="section-story-overview pb-0 pt-2 mt-0">
          <div class="row">
            <div class="col-md-6">
              <div class="image-container image-left" :style="'background-image: url(' + em_zeremonie2 + ')'">
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary" style="min-height: 0; width: 320px; ">
                  Danke, dass Ihr unseren Hochzeitstag zu einem der schönsten Tage in unserem Leben gemacht habt.
                </p>
              </div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div class="image-container image-right mt-4" :style="'background-image: url(' + em_zeremonie1 + ')'">
              </div>
              <h5 class="description mb-0 pb-0">
                <p> Wir freuen uns so sehr darauf,
                  dieses nächste Kapitel unseres Lebens zu beginnen
                  und viele weitere Erinnerungen mit Euch zu teilen.</p>
              </h5>
            </div>
          </div>
        </div>
      </h5>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/pages/components/SectionTitle";

export default {
  name: "Thanks",
  components: {
    SectionTitle
  },
  data() {
    return {
      em_zeremonie1: require("../../../public/em_zeremonie1.png"),
      em_zeremonie2: require("../../../public/em_zeremonie2.png"),
      em_zeremonie3: require("../../../public/em_zeremonie3.png"),
    }
  },
}
</script>

<style></style>