import "bootstrap/dist/css/bootstrap.css";
import "@/assets/scss/kit.scss";
//import globalDirectives from './globalDirectives';
import globalMixins from "./globalMixins";
import globalComponents from "./globalComponents";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import VueLazyload from "vue3-lazyload";

locale.use(lang);

export default {
  install(app) {
    app.use(globalMixins);
    app.use(globalComponents);
    //    app.directive(globalDirectives);
    app.use(VueLazyload, {
      observer: true,
      // optional
      observerOptions: {
        rootMargin: "0px",
        threshold: 0.1,
      },
    });
  },
};
