<template>
  <div>
    <div class="page-header clear-filter" filter-color="purple">
      <div class="page-header-image" style="max-height:100vh; padding: -5rem;"
        :style="'background-image: url(' + em_bg + ')'">
      </div>
    </div>
    <card id="login-mask" type="login" plain class="mt-5">
      <fg-input class="no-border input-lg pt-1 mb-0" style="font-size: 1rem" type="password" v-model="pw"
        addon-left-icon="kit-icons objects_key-25" placeholder="Passwort (siehe Einladung)" v-on:keyup.enter="log_in(pw)">
      </fg-input>
      <a v-on:click="log_in(pw)" class="btn btn-round btn-lg btn-block btn-primary">
        Login
      </a>
      <p class="font-weight-bold text-danger pt-4 text-center" style="height: 3rem"><span v-if="wrongLogin">{{ wrongPwText
      }}</span></p>

    </card>
  </div>
</template>

<script>
import { Button, Card, FormGroupInput } from '@/components';
import auth from '../../secret.json'
import sha256 from 'crypto-js/sha256';

export default {
  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      em_bg: require("../assets/img/em_bg.png"),
      pw_hash: auth.pw_hash,
      salt: auth.salt,
      pw: '',
      wrongPwText: "Falsches Passwort!",
      wrongLogin: false,
    }
  },
  methods: {
    string_to_hash(pw) {
      return sha256(pw + this.salt).toString();
    },
    log_in(pw) {
      if (this.string_to_hash(pw) === this.pw_hash) {
        this.$cookies.set("pw_hash", this.string_to_hash(pw), "30d")
        this.$emit('login', true);
      } else {
        this.wrongLogin = true;
      }
    }
  },
  mounted() {
    let parameters = this.$route.query;
    let password = parameters.pw;
    if (password) {
      this.log_in(password);
    }
  }
};
</script>

<style>
.input-group {
  margin-bottom: 1rem !important;
}

#login-mask {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
