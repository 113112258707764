<template>
  <div>
    <SectionTitle :title="'Fotos'"></SectionTitle>
    <div class="container">
      <h5 class="mb-4 text-center">
        <p> <b>Bitte teilt Eure Fotos mit uns!</b> </p>
        <p>
          Wir freuen uns, wenn Ihr Fotos, die Ihr am Hochzeitstag gemacht habt, in das folgende Album hochladet. <br>
          Dort findet Ihr auch die Bilder der anderen Gäste.<br>
          <b><a href="https://photos.app.goo.gl/ohowuKmsKuKquKTt8" target="_blank">E&M 6.7.2024 - Foto-Upload</a></b>
        </p><br>
        <p>
          In folgendem Album haben wir die Fotos der <b>Fotobox</b> sowie der <b>Gästebuchkamera</b> zur Verfügung
          gestellt:<br>
          <b><a href="https://photos.app.goo.gl/xXGADaFExuEEGd6E7" target="_blank">E&M 6.7.2024 - Fotobox &
              Gästebuch</a></b>
        </p><br>
        <p>
          Auf Bilder, die unsere <b><a href="https://lauraschleichphotography.de/" target="_blank">Fotografin
              Laura</a></b>
          gemacht hat, könnt Ihr hier zugreifen : <br>
          <b><a href="https://photos.app.goo.gl/9b8Z1Ty8RVaQZyJ97" target="_blank">E&M 6.7.2024 - Fotoalbum</a></b>
        </p>
      </h5>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/pages/components/SectionTitle";

export default {
  name: "Photos",
  components: {
    SectionTitle
  }
}
</script>

<style scoped></style>