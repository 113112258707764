<template>
  <navbar id="start" position="fixed" type="primary" :transparent="false" :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto">
    <scrollactive class="navbar-brand">
      <a href="#start" class="scrollactive-item nav-link">
        <img :src="em_brand" style="height: 1.5rem" />
      </a>
    </scrollactive>
    <template v-slot:navbar-menu>
      <scrollactive class="my-nav" :offset="80">
        <a href="#thanks" class="scrollactive-item nav-link" style="width: 8.5rem">
          <i class="kit-icons objects_diamond"></i>&nbsp;
          Danke!
        </a>
      </scrollactive>
      <scrollactive class="my-nav" :offset="80">
        <a href="#photos" class="scrollactive-item nav-link" style="width: 8.5rem">
          <i class="kit-icons media-1_camera-compact"></i>&nbsp;
          Fotos
        </a>
      </scrollactive>
      <!--
      <scrollactive class="my-nav" :offset="80">
          <a href="#savethedate" class="scrollactive-item nav-link" style="width: 8.5rem">
            <i class="kit-icons objects_diamond"></i>&nbsp;
            Save the Date
          </a>
      </scrollactive>
      <scrollactive :offset="80">
          <a href="#location" class="scrollactive-item nav-link" style="width: 7rem">
            <i class="kit-icons location_pin"></i>&nbsp;
            Location
          </a>
      </scrollactive>
      <scrollactive :offset="80">
        <a href="#ablauf" class="scrollactive-item nav-link" style="width: 6rem">
          <i class="kit-icons ui-1_calendar-60"></i>&nbsp;
          Ablauf
        </a>
      </scrollactive>
      <scrollactive :offset="80">
        <a href="#anfahrt" class="scrollactive-item nav-link" style="width: 7rem">
          <i class="kit-icons location_compass-05"></i>&nbsp;
          Anfahrt
        </a>
      </scrollactive>
      <scrollactive :offset="80">
        <a href="#unterkunft" class="scrollactive-item nav-link" style="width: 8rem">
          <i class="kit-icons shopping_shop"></i>&nbsp;
          Unterkunft
        </a>
      </scrollactive>
    -->
      <scrollactive :offset="80">
        <a href="#kontakt" class="scrollactive-item nav-link" style="width: 7rem">
          <i class="kit-icons ui-1_email-85"></i>&nbsp;
          Kontakt
        </a>
      </scrollactive>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
    [Popover.name]: Popover
  },
  data() {
    return {
      em_brand: require("../../public/em_start.png"),
    }
  }
};
</script>

<style scoped>
.navbar {
  max-width: 100vw !important;
}

a:hover {
  font-weight: bold;
}
</style>
