<template>
  <div class="row">
    <div class="col">
      <h3>{{ days }}</h3>
      <p>Tage</p>
    </div>
    <div class="col">
      <h3>{{ hours }}</h3>
      <p>Stunden</p>
    </div>
    <div class="col">
      <h3>{{ minutes }}</h3>
      <p>Minuten</p>
    </div>
    <div class="col">
      <h3>{{ seconds }}</h3>
      <p>Sekunden</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Counter",
  props: ['goal_date'],
  mounted: function () {
    this.diff = setInterval(() => {
      this.countDown()
    }, 1000)
  },
  data: function () {
    return {
      current_date: new Date,
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
    }
  },
  methods: {
    countDown() {
      this.current_date = new Date;
      this.days = Math.floor(this.date_diff(this.current_date, this.goal_date) / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((this.date_diff(this.current_date, this.goal_date) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((this.date_diff(this.current_date, this.goal_date) % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((this.date_diff(this.current_date, this.goal_date) % (1000 * 60)) / 1000);
    },
    date_diff(date1, date2) {
      return Math.floor(Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate(), date2.getHours(), date2.getMinutes(), date2.getSeconds())
        - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate(), date1.getHours(), date1.getMinutes(), date1.getSeconds()))
    },
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
h3 {
  height: 2.3rem
}
</style>