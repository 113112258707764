<template>
  <div>
    <cookie-law class="d-flex justify-content-center" theme="base" :message="'Diese Seite verwendet 🍪!'" :button-text="'Alles klar!'">
    </cookie-law>
    <footer
      class="footer"
      :class="{ [`footer-${type}`]: type }"
      :data-background-color="backgroundColor"
    >
      <div class="container">
        <nav>
          <ul>
            <li>
                06.07.2024
            </li>
          </ul>
        </nav>
        <div class="copyright"><p style="color:#383838">Crafted with <i class="pl-1 pr-1 text kit-icons ui-2_favourite-28"></i> by Max for Evi</p></div>
      </div>
    </footer>
  </div>
</template>
<script>
import CookieLaw from 'vue-cookie-law'

export default {
  components: { CookieLaw },
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
.Cookie__content {
  color: white !important;
}

.Cookie {
  background: #A78A6B !important;
}

.Cookie__button {
  background: #444 !important;
  border-radius: 3px !important;
}
</style>
