<template>
  <div id="app">
    <div class="wrapper" v-if="!logged_in">
      <LoginPage @login="logged_in=true" :wrongLogin="wrongLogin"></LoginPage>
    </div>
    <div v-if="logged_in">
      <MainNavbar :colorOnScroll="400"
                  v-motion
                  :initial="{opacity: 0, y: -150 }"
                  :enter="{ opacity: 1, y: 0, scale: 1, transition: { stiffness: 500, mass: 0.1} }"
                  :delay="1300"
                  :duration="100"
      />
      <div class="wrapper">
        <Profile/>
      </div>
      <MainFooter :backgroundColor="'black'" />
    </div>
  </div>
</template>
<script>
import auth from '../secret.json';
import LoginPage from "@/pages/Login";
import MainNavbar from "@/layout/MainNavbar";
import MainFooter from "@/layout/MainFooter";
import Profile from "@/pages/Profile";

export default {
  name: 'App',
  components: {
    Profile,
    MainFooter,
    MainNavbar,
    LoginPage
  },
  props: ['query'],
  data () {
    return {
      pw_hash: auth.pw_hash,
      cookie: '',
      salt: auth.salt,
      logged_in: false,
      wrongLogin: false,
    }
  },
  mounted() {
    let cookie_pw_hash = this.$cookies.get('pw_hash');
    this.logged_in = cookie_pw_hash === this.pw_hash;
  },
};
</script>

<style>
html, body {
  max-width: 100%;
  overflow-x: hidden;
}
</style>