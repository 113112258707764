<template>
  <div class="row">
    <div class="col"></div>
    <h2 class="title line col-12 col-md-7 col-lg-5" style="font-family:'Italiana',serif">&nbsp;{{ title }}&nbsp;</h2>

    <div class="col"></div>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: {
      type: String,
      default: "Change Title"
    }
  }
}
</script>

<style scoped>
.line {
  display: flex;
  flex-direction: row;
}
.line:before,
.line:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
</style>