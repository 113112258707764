<template>
  <div id="profile">
    <div class="page-header clear-filter border-primary" style="border-bottom: 1px solid !important">
      <parallax class="page-header-image" :style="'background-image:url(' + bg + ')'">
      </parallax>
      <Title v-motion :initial="{ opacity: 0, y: 0 }" :enter="{ opacity: 1, y: 0, scale: 1 }" :delay="500"
        :duration="500">
      </Title>
      <div class="content mt-0 mb-0" v-motion :initial="{ opacity: 0, y: 0 }" :enter="{ opacity: 1, y: 0, scale: 1 }"
        :delay="1500" :duration="2000">
        <div class="row">
          <div class="col col-12 line-golden">
            <p style="font-size: 1.3rem;letter-spacing: 3px; margin-top: 1rem">
              &nbsp;{{ goal_date.getDate() }}. {{ getMonthName(goal_date.getMonth()) }} {{ goal_date.getFullYear()
              }}&nbsp;
            </p>
          </div>
        </div>
      </div>
      <Counter v-if="false" v-motion :initial="{ opacity: 0, y: 0 }" :enter="{ opacity: 1, y: 0, scale: 1 }" :delay="1500"
        :duration="2000" class="container content mt-1 pt-0 mb-0" :goal_date="goal_date" />
    </div>
    <div class="section mt-0 pt-0">
      <div class="container">
        <Thanks id="thanks" v-motion :initial="{ opacity: 0, y: 0 }" :enter="{ opacity: 1, y: 0, scale: 1 }" :delay="1500"
          :duration="2000"></Thanks>
        <Photos id="photos" v-motion :initial="{ opacity: 0, y: 0 }" :enter="{ opacity: 1, y: 0, scale: 1 }" :delay="1500"
          :duration="2000"></Photos>
        <Contact id="kontakt" v-motion :initial="{ opacity: 0, y: 0 }" :enter="{ opacity: 1, y: 0, scale: 1 }"
          :delay="1500" :duration="2000"></Contact>
      </div>
    </div>
  </div>
</template>

<script>
import Thanks from "@/pages/content/Thanks";
import Photos from "@/pages/content/Photos";
import Contact from "@/pages/content/Contact";

import Title from "./components/Title";
import Counter from "./components/Counter";

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  props: ['logged_in'],
  components: {
    Thanks,
    Photos,
    Contact,
    Title,
    Counter
  },
  data() {
    return {
      goal_date: new Date("2024-07-06T15:00:00"),
      bg: require("../assets/img/em_bg.png")
    }
  },
  methods: {
    getMonthName(month) {
      switch (month) {
        case 0: return "Januar"
        case 1: return "Februar"
        case 2: return "März"
        case 3: return "April"
        case 4: return "Mai"
        case 5: return "Juni"
        case 6: return "Juli"
        case 7: return "August"
        case 8: return "September"
        case 9: return "Oktober"
        case 10: return "November"
        case 11: return "Dezember"
      }
    }
  },
  mounted() {
    this.$router.push('/');
  }
};
</script>

<style>
.line-golden {
  display: flex;
  flex-direction: row;
}

.line-golden:before,
.line-golden:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #A78A6B;
  margin: auto;
}
</style>
