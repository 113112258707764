import { createApp } from "vue";
import App from "./App.vue";
import Kit from "./plugins/kit";
import VueScrollactive from "vue-scrollactive";
import VueCookies from "vue-cookies";
import { MotionPlugin } from "@vueuse/motion";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { createWebHistory, createRouter } from "vue-router";
import vClickOutside from "v-click-outside";

const routes = [
  {
    path: "/",
    name: "App",
    component: App,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.use(VueCookies, { expires: "30d" });
app.use(VueGoogleMaps, {
  load: {
    v: "quarterly",
    key: "AIzaSyBLv8rCLcR9IcfMKlPn39urCB2tdGtI5F4",
    language: "de",
    libaries: "places",
  },
});
app.use(Kit);
app.use(VueScrollactive);
app.use(MotionPlugin);
app.use(vClickOutside);
app.config.warnHandler = function (msg, vm, trace) {
  return null;
};

router.isReady().then(() => {
  app.mount("#app");
});
